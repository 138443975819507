import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/verifyemail",
      name: "verifyemail",
      component: () => import("@/view/pages/email/VerifyEmail.vue"),
    },
    {
      path: "/reset/:token",
      name: "reset",
      component: () => import("@/view/pages/auth/reset/ResetPassword.vue"),
    },
    {
      path: "/runsheet",
      name: "runsheet",
      component: () => import("@/view/pages/driver/RunSheet.vue")
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        //Container
        {
          path: "/container",
          name: "container",
          component: () => import("@/view/pages/prealert/PreAlert.vue"),
          children: [
            {
              path: "add",
              name: "add",
              component: () =>
                import("@/view/pages/container/AddContainer.vue"),
            },
            {
              path: "all",
              name: "all",
              component: () =>
                import("@/view/pages/container/AllContainer.vue"),
            },
            {
              path: "viewcontainer/:id",
              name: "viewcontainer",
              component: () =>
                import("@/view/pages/container/ViewContainer.vue"),
            },
            {
              path: "updateshipment/:id",
              name: "updateshipment",
              component: () =>
                import(
                  "@/view/pages/profile/profile-comp/PersonalInformation.vue"
                ),
            },
            {
              path: "add-shipment-admin",
              name: "add-shipment-admin",
              component: () =>
                import("@/view/pages/container/AddContainer.vue"),
            },
            {
              path: "book-shipment",
              name: "book-shipment",
              component: () =>
                import("@/view/pages/prealert/BookShipmentNav.vue"),
            },
            {
              path: "add-shipment",
              name: "add-shipment",
              component: () => import("@/view/pages/prealert/AddShipment.vue"),
            },
            {
              path: "view-shipment/:id",
              name: "view-shipment",
              component: () => import("@/view/pages/prealert/ViewShipment.vue"),
            },
            {
              path: "shipments",
              name: "shipments",
              component: () => import("@/view/pages/prealert/MyShipments.vue"),
            },
            {
              path: "container-admin",
              name: "container-admin",
              component: () =>
                import("@/view/pages/container/AllContainer.vue"),
            },
          ],
        },
        //Prealert
        {
          path: "/prealert",
          name: "prealert",
          component: () => import("@/view/pages/prealert/PreAlert.vue"),
          children: [
            {
              path: "add",
              name: "add",
              component: () => import("@/view/pages/prealert/AddPreAlert.vue"),
            },
            {
              path: "updateshipment/:id",
              name: "updateshipment",
              component: () =>
                import(
                  "@/view/pages/profile/profile-comp/PersonalInformation.vue"
                ),
            },
            {
              path: "add-shipment-admin",
              name: "add-shipment-admin",
              component: () =>
                import("@/view/pages/prealert/AddShipmentAdmin.vue"),
            },
            {
              path: "book-shipment",
              name: "book-shipment",
              component: () =>
                import("@/view/pages/prealert/BookShipmentNav.vue"),
            },
            {
              path: "add-shipment",
              name: "add-shipment",
              component: () => import("@/view/pages/prealert/AddShipment.vue"),
            },
            {
              path: "view-shipment/:id",
              name: "view-shipment",
              component: () => import("@/view/pages/prealert/ViewShipment.vue"),
            },
            {
              path: "shipments",
              name: "shipments",
              component: () => import("@/view/pages/prealert/MyShipments.vue"),
            },
            {
              path: "shipments-admin",
              name: "shipments-admin",
              component: () => import("@/view/pages/prealert/AllShipments.vue"),
            },
            {
              path: "prealert-admin",
              name: "prealert-admin",
              component: () => import("@/view/pages/prealert/AllPrealerts.vue"),
            },
          ],
        },
        //Drivers
        {
          path: "/drivers",
          name: "drivers",
          component: () => import("@/view/pages/driver/DriverLayout.vue"),
          children: [
            {
              path: "all",
              name: "all-drivers",
              component: () => import("@/view/pages/driver/AllDrivers.vue")
            },
            {
              path: "view/:id",
              name: "view-driver",
              component: () => import("@/view/pages/driver/ViewDriver.vue")
            }
          ]
        },
        //Users
        {
          path: "/users",
          name: "usersmain",
          component: () => import("@/view/pages/users/Users.vue"),
          children: [
            {
              path: "users",
              name: "users",
              component: () => import("@/view/pages/users/AllUsers.vue"),
            },
            {
              path: "customers",
              name: "customers",
              component: () => import("@/view/pages/users/Customers.vue"),
            },
            {
              path: "cdashboard/:id",
              name: "cdashboard",
              component: () => import("@/view/pages/users/Customer-Dashboard.vue"),
            },
          ],
        },
        //Consignes
        {
          path: "/consigne",
          name: "consignes",
          component: () => import("@/view/pages/consigne/consigne.vue"),
          children: [
            {
              path: "all",
              name: "all",
              component: () => import("@/view/pages/consigne/All.vue"),
            }
          ],
        },
        //Shipping
        {
          path: "/shipping",
          name: "shipping",
          component: () => import("@/view/pages/shipping/Shipping.vue"),
          children: [
            {
              path: "rate",
              name: "rate",
              component: () => import("@/view/pages/shipping/Rate.vue"),
            },
            {
              path: "search-rate",
              name: "search-rate",
              component: () => import("@/view/pages/shipping/SearchRate.vue"),
            },
            {
              path: "view-rate/:id",
              name: "view-rate",
              component: () => import("@/view/pages/shipping/ViewRate.vue"),
            },
            {
              path: "currency",
              name: "conversion-currency",
              component: () =>
                import("@/view/pages/shipping/ConversionCurrency.vue"),
            },
            {
              path: "zone",
              name: "shipping-zone",
              component: () => import("@/view/pages/shipping/Zone.vue"),
            },
            {
              path: "view-zone/:id",
              name: "view-zone",
              component: () => import("@/view/pages/shipping/ViewZone.vue"),
            },
          ],
        },
        //Settings
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/settings/Settings.vue"),
          children: [
            {
              path: "branch",
              name: "branch",
              component: () => import("@/view/pages/settings/Branch.vue"),
            },
            {
              path: "country",
              name: "country",
              component: () => import("@/view/pages/settings/Country.vue"),
            },
            {
              path: "state",
              name: "state",
              component: () => import("@/view/pages/settings/State.vue"),
            },
            {
              path: "virtual-address",
              name: "virtualaddress",
              component: () =>
                import("@/view/pages/settings/Virtualaddress.vue"),
            },
            {
              path: "courier",
              name: "courier",
              component: () => import("@/view/pages/settings/Courier.vue"),
            },
            {
              path: "product-description",
              name: "product-description",
              component: () =>
                import("@/view/pages/settings/Productdescription.vue"),
            },
            {
              path: "shipping-mode",
              name: "shipping-mode",
              component: () => import("@/view/pages/settings/Shippingmode.vue"),
            },
            {
              path: "shipment-type",
              name: "shipment-type",
              component: () => import("@/view/pages/settings/ShipmentType.vue"),
            },
            {
              path: "weight-scale",
              name: "weight-scale",
              component: () => import("@/view/pages/settings/WeightScale.vue"),
            },
            {
              path: "size-scale",
              name: "size-scale",
              component: () => import("@/view/pages/settings/SizeScale.vue"),
            },
            {
              path: "currency",
              name: "currency",
              component: () => import("@/view/pages/settings/Currency.vue"),
            },
            {
              path: "shipment-payer",
              name: "shipment-payer",
              component: () =>
                import("@/view/pages/settings/ShipmentPayer.vue"),
            },
            {
              path: "address-book",
              name: "address-book",
              component: () => import("@/view/pages/settings/Addressbook.vue"),
            },
            {
              path: "permission",
              name: "permission",
              component: () =>
                import("@/view/pages/settings/RolesAndPermission.vue"),
            },
            {
              path: "shipment-status-list",
              name: "shipment-status-list",
              component: () =>
                import("@/view/pages/settings/ShipmentStatusList.vue"),
            },
            {
              path: "remarks-status-list",
              name: "remarks-status-list",
              component: () =>
                import("@/view/pages/settings/RemarksStatusList.vue"),
            },
            {
              path: "vat",
              name: "vat",
              component: () =>
                import("@/view/pages/settings/Vat.vue"),
            },
          ],
        },
        //Profile
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "user-profile",
              name: "user-profile",
              component: () => import("@/view/pages/profile/User-Profile.vue")
            }
          ]
        }
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("@/view/pages/auth/signup/Signup.vue"),
      children: [
        {
          path: "register",
          name: "register",
          component: () => import("@/view/pages/auth/signup/Register.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },

        // {
        //   name: "register",
        //   path: "/register",
        //   component: () => import("@/view/pages/auth/login_pages/Login-1")
        // }
      ],
    },

    {
      path: "/track",
      component: () =>
        import("@/view/pages/track-shipment/TrackShipmentModule.vue"),
      children: [
        {
          path: "",
          name: "track",
          component: () => import("@/view/pages/track-shipment/Track.vue"),
        },
        {
          path: "shipment/:trackingNo",
          name: "track-shipment",
          component: () =>
            import("@/view/pages/track-shipment/TrackShipment.vue"),
        },
      ],
    },
    {
      path: "/manifest",
      component: () =>
        import("@/view/pages/manifest/manifestModule.vue"),
      children: [
        {
          path: "print/:id",
          name: "print-manifest",
          component: () => import("@/view/pages/manifest/manifest.vue"),
        }
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
  // mode: "history"
});
